/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Tooltip,
  Divider,
  Grid,
  Box,
  Button,
  TextField,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
// import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Toolbar from '@material-ui/core/Toolbar';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Delete from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable, { TableFilterList } from 'mui-datatables';
import { format } from 'date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import DialogTelaCheia from '../../../components/DialogTelaCheia';
import MensagemAlerta from '../../../components/MensagemAlerta';
import BackDrop from '../../../components/BackDrop';
import MenuTemporario from '../../../components/MenuTemporario';
import api from '../../../services/api';
import db from '../../../firebase/firebase';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(1),
  },
  cardpedidospendentes: {
    paddingTop: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
}));

// eslint-disable-next-line react/prop-types
const CustomChip = ({ label, onDelete }) => {
  return (
    <Chip
      variant="outlined"
      color="secondary"
      label={label}
      onDelete={onDelete}
    />
  );
};

const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

const PedidoDataTable = () => {
  const classes = useStyles();

  const [qtpedidospendentesenvio, setqtpedidospendentesenvio] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const [isOpen2, setisOpen2] = useState(false);
  const [isOpen3, setisOpen3] = useState(false);
  // const [desativabotaosincronizar, setdesativabotaosincronizar] = useState(false);
  const [pedidospendentes, setPedidosPendentes] = useState([]);
  const [sumarizarfamilia, setSumarizarFamilia] = useState([]);
  const [itenspedido, setItensPedido] = useState([]);
  const [itenspedidohoje, setItensPedidoHoje] = useState([]);
  // const [pedidospendenteenvio, setPedidosPendenteEnvio] = useState([]);
  const [state, setState] = useState({
    open: false,
    texto: '',
  });
  const [isFetching, setisFetching] = useState(true);
  const [clientes, setClientes] = React.useState([]);
  const [codcliente, setCodCliente] = React.useState(null);
  // const [pedidonaoenviado, setPedidoNaoEnviado] = React.useState(null);
  const formatadata = (data) => format(data, "yyyy'-'MM'-'dd");
  const [pedidos, setPedidos] = useState([]);
  // const [produtospendenteenvio, setprodutospendenteenvio] = useState([]);
  // const [isFetching, setisFetching] = useState(false);
  const [datainicial, setDataInicial] = React.useState(new Date());
  const [datafinal, setDataFinal] = React.useState(new Date());
  // eslint-disable-next-line max-len
  const [datainicialformatada, setdatainicialformatada] = React.useState(formatadata(new Date()));
  const [datafinalformatada, setdatafinalformatada] = React.useState(formatadata(new Date()));

  const handleCloseMenuRapido = () => {
    setisOpen2(false);
  };

  const handleCloseMenuRapido2 = () => {
    setisOpen3(false);
  };

  const handleDataFinal = (e) => {
    const resultado = formatadata(e);
    setdatafinalformatada(resultado);
    // setdatafinalformatada(resultado);
    setDataFinal(e);
  };
  const handleDataInicial = (e) => {
    const resultado = formatadata(e);
    // setdatainicialformatada(resultado);
    setdatainicialformatada(resultado);
    setDataInicial(e);
  };

  const handleDialogOpen = () => {
    setisOpen(true);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };
  /*
  const handleSincronizarPedidos = () => {
    console.log('sincronizar pedidos');
  };
*/
  const handleCloseMensagem = () => {
    setState({
      ...state,
      open: false,
    });
  };

  const ItensPedido = (e) => {
    setItensPedido(e);
    //  console.log(e);
    setisOpen2(true);
  };

  const cancelarPedidoHandle = (e) => {


    const MySwal = withReactContent(Swal);

    MySwal.fire({
      title: 'Deseja cancelar o Pedido?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Cancelar',
      denyButtonText: `Voltar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        
        let send_data = {}

        if (e) {
          send_data = { codcliente: e.cod_cliente, nomeabrev: e.cliente, numpedido: e.num_pedido_cliente, tipo: "1", dataentrega: e.dataentregaformatada }
        }
        

        api.post('/api-pedidos/alteracaopedidovenda', send_data)
          .then(response => console.log(response.data));

        
        let dado_cancelamento = {}

        if (e) {
          dado_cancelamento = {
            num_pedido_cliente: e.num_pedido_cliente,
            cod_cliente: e.cod_cliente,
            cliente: e.cliente,
            dataentrega: e.dataentregaformatada, 
            pedido_venda: 161726
          }
        }

        api.post('/api-pedidos/cancelar', dado_cancelamento)
          .then(response => console.log(response.data));
          

        MySwal.fire('Cancelamento enviado', '', 'success')


      } else if (result.isDenied) {
        MySwal.fire('Cancelamento não enviado', '', 'info')
      }
    })

  }



  const ItensPedido2 = (e) => {
    setItensPedidoHoje(e);
    //  console.log(e);
    setisOpen3(true);
  };
  

  const sincronizarpedidos = async () => {
    console.log('sincronizado');
  };

  const consultarPedidos = async () => {
    let encontrou = false;
    const usuariologado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
    if (codcliente == null) {
      setisFetching(true);
      api.post(
        '/api-pedidos/vendedordata',
        {
          codrep: usuariologado.codigo,
          dtinicio: datainicialformatada,
          dtfim: datafinalformatada
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then((response) => {
        setisFetching(false);
        if (response.data.data === null) {
          encontrou = false;
        } else {
          setPedidos(response.data.data);
          encontrou = true;
          handleDialogOpen(true);
        }
      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);

          // console.log(error.response.headers);
        } else if (error.request) {
          setState({
            ...state,
            texto: 'Consulta só disponivel com sinal de dados disponivel',
            open: true
          });
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        }
      });
      if (encontrou === false) {
        setisFetching(true);
        api.post(
          '/api-pedidos/vendedorinternodata',
          {
            email: usuariologado.email,
            dtinicio: datainicialformatada,
            dtfim: datafinalformatada
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        ).then((response) => {
          setisFetching(false);
          if (response.data.data === null) {
            encontrou = false;
          } else {
            setPedidos(response.data.data);
            encontrou = true;
            handleDialogOpen(true);
          }
        }).catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);

            // console.log(error.response.headers);
          } else if (error.request) {
            setState({
              ...state,
              texto: 'Consulta só disponivel com sinal de dados disponivel',
              open: true
            });
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          }
        });
      }
    } else {
      setisFetching(true);
      api.post(
        '/api-pedidos/vendedorclientedata',
        {
          codrep: usuariologado.codigo,
          codcliente: codcliente.codigo,
          dtinicio: datainicialformatada,
          dtfim: datafinalformatada
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then((response) => {
        setisFetching(false);
        if (response.data.data === null) {
          encontrou = false;
        } else {
          setPedidos(response.data.data);
          encontrou = true;
          handleDialogOpen(true);
        }
      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);

          // console.log(error.response.headers);
        } else if (error.request) {
          setState({
            ...state,
            texto: 'Consulta só disponivel com sinal de dados disponivel',
            open: true
          });
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        }
      });

      if (encontrou === false) {
        setisFetching(true);
        api.post(
          '/api-pedidos/vendedorinternoclientedata',
          {
            email: usuariologado.email,
            codcliente: codcliente.codigo,
            dtinicio: datainicialformatada,
            dtfim: datafinalformatada
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        ).then((response) => {
          setisFetching(false);
          if (response.data.data === null) {
            encontrou = false;
          } else {
            setPedidos(response.data.data);
            encontrou = true;
            handleDialogOpen(true);
          }
        }).catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);

            // console.log(error.response.headers);
          } else if (error.request) {
            setState({
              ...state,
              texto: 'Consulta só disponivel com sinal de dados disponivel',
              open: true
            });
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          }
        });
      }
    }
    setisFetching(false);
  };

  const columns = [
    {
      name: 'itens',
      options: {
        display: 'excluded',
        filter: false
      }
    },
    {
      name: 'datapedido',
      label: 'Data',
      options: {
        customBodyRender: (value) => {
          //  console.log(tableMeta);
          return (<b>{moment(value).format('DD/MM/YYYY')}</b>);
        },
        filter: false,
      }
    },
    {
      name: 'tipopedido',
      label: 'Tipo',
      options: {
        customBodyRender: (value) => {
          //  console.log(tableMeta);
          return (<Avatar>{value.substring(0, 1)}</Avatar>);
        },
        filter: false,
      }
    },
    {
      name: 'numpedrep',
      label: 'Pedido Representante',
      options: {
        filter: false,
        // display: "excluded"
      }
    },
    {
      name: 'numpedido',
      label: 'Pedido Cliente',
      options: {
        filter: false,
        // display: "excluded"
      }
    },
    {
      name: 'codcliente',
      label: 'Cod. Cliente',
      options: {
        filter: false
        // display: "excluded"
      }
    },
    {
      name: 'nomecliente',
      label: 'Cliente',
      options: {
        filter: false
        // display: "excluded"
      }
    },
    {
      name: 'desstpedido',
      label: 'Situação',
      options: {
        filter: true
        // display: "excluded"
      }
    },
    {
      name: 'totalpedido',
      label: 'Total',
      options: {
        customBodyRender: (value) => {
          //  console.log(tableMeta);
          return (new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
          }).format(value));
        },
        filter: false,
      }
    },
    {
      name: '',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Chip
              icon={<FindInPageIcon />}
              label="Itens Pedido"
              onClick={() => { ItensPedido(tableMeta.rowData[0]); }}
            />
          );
        },
        filter: false,
        sort: false,
        empty: true
        // display: "excluded"
      }
    }, 
  ];

  const options = {
    filterType: 'checkbox',
    pagination: false,
    filter: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: 'auto',
    download: false,
    viewColumns: false,
    selectableRows: 'none',
    rowsPerPage: 10,
    responsive: 'simple',
    textLabels: {
      pagination: {
        next: 'Próximo',
        previous: 'Anterior',
        rowsPerPage: 'linhas por Pagina:',
        displayRows: 'de'
      },
      body: {
        noMatch: 'Nenhum registro encontrado',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenado por ${column.label}`
      },
      filter: {
        all: 'Todos',
        title: 'FILTRO',
        reset: 'LIMPAR'
      }
    }

  };

  useEffect(() => {
    // const clientepedido = JSON.parse(localStorage.getItem('CLIENTEPEDIDO'));
    // setPedidoNaoEnviado(clientepedido);
    setClientes(JSON.parse(localStorage.getItem('MEUSCLIENTES')));
    // setPedidosPendenteEnvio(JSON.parse(localStorage.getItem('PEDIDOSPENDENTESENVIO'))); comentado verificar
    // const qtpedidospendentesenvio = JSON.parse(localStorage.getItem('PEDIDOSPENDENTESENVIO'));

    // if (qtpedidospendentesenvio.length === 0) setdesativabotaosincronizar(true);
    // console.log(pedidospendenteenvio);
    setState({ open: false });
    //  console.log(clientes);
    setPedidos([]);
    const vendedorlogado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));

    async function fetchData() {
      setisFetching(true);
      const response = await api.post(
        '/pedidos-sales/v2/pedido/pedidospendentesintegracao',
        { codvendedor: vendedorlogado.codigo },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      setPedidosPendentes(response.data);

      const response2 = await api.post(
        '/pedidos-sales/v2/pedido/sumarizarvendasfamilia',
        { codvendedor: vendedorlogado.codigo },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      console.log("retorno de sumarizacao da familia");
      console.log(response2.data);
      setSumarizarFamilia(response2.data);
      setisFetching(false);
      
    }
    fetchData();
  }, []);

  return (
    <>
      <MenuTemporario isOpen={isOpen2} handleClose={handleDialogClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseMenuRapido} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Código</TableCell>
                <TableCell align="right">Descrição</TableCell>
                <TableCell align="right">Quantidade Pedida</TableCell>
                <TableCell align="right">Quantidade Atendida</TableCell>
                <TableCell align="right">Valor Venda</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itenspedido.map((row) => (
                <TableRow key={row.coditem}>
                  <TableCell component="th" scope="row">
                    {row.coditem}
                  </TableCell>
                  <TableCell align="right">{row.descitem}</TableCell>
                  <TableCell align="right">{Number(row.qtpedida).toFixed(2)}</TableCell>
                  <TableCell align="right">{Number(row.qtatendida).toFixed(2)}</TableCell>
                  <TableCell align="right">
                    R$
                    {Number(row.vlvenda).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </MenuTemporario>
      <MenuTemporario isOpen={isOpen3} handleClose={handleDialogClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseMenuRapido2} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Código</TableCell>
                <TableCell align="right">Descrição</TableCell>
                <TableCell align="right">Quantidade Pedida</TableCell>
                <TableCell align="right">Valor Venda</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itenspedidohoje.map((row) => (
                <TableRow key={row.cd_produto}>
                  <TableCell component="th" scope="row">
                    {row.cd_produto}
                  </TableCell>
                  <TableCell align="right">{row.produto}</TableCell>
                  <TableCell align="right">{row.qt_item}</TableCell>
                  <TableCell align="right">
                    {' '}
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2
                    }).format((row.vr_item_original / row.qt_item))}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell>
                  <b>
                    {
                      new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2
                      }).format(itenspedidohoje.map((item) => Number(item.vr_item_original)).reduce((prev, next) => prev + next, 0))
                    }
                  </b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </MenuTemporario>
      <BackDrop open={isFetching} />
      <div className={classes.root}>
        <MensagemAlerta
          isOpen={state.open}
          texto={state.texto}
          nivel="warning"
          handleClose={handleCloseMensagem}
        />
        <Card>
          <CardHeader title="Pedidos Faturados" />
          <Box
            display="flex"
            justifyContent="flex-start"
            p={1}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <Autocomplete
                  id="cliente"
                  options={clientes}
                  onChange={(event, v) => setCodCliente(v)}
                  value={codcliente}
                  getOptionLabel={(option) => option.fantasia}
                  // style={{ width: 400 }}
                  renderInput={(params) => <TextField {...params} label="Cliente*" variant="outlined" />}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={ptLocale}
                >
                  <DatePicker
                    margin="normal"
                    label="Data Inicial"
                    inputVariant="outlined"
                    id="datainicial"
                    format="dd/MM/yyyy"
                    value={datainicial}
                    onChange={handleDataInicial}
                  />
                </MuiPickersUtilsProvider>

              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={ptLocale}
                >
                  <DatePicker
                    margin="normal"
                    label="Data Final"
                    inputVariant="outlined"
                    id="datafinal"
                    format="dd/MM/yyyy"
                    minDate={datainicial}
                    value={datafinal}
                    onChange={handleDataFinal}
                  />
                </MuiPickersUtilsProvider>

              </Grid>

            </Grid>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            p={1}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={consultarPedidos}
                >
                  Consultar
                </Button>

              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                {qtpedidospendentesenvio > 0
                  && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={sincronizarpedidos}
                    >
                      Sincronizar Pedidos
                    </Button>
                  )}

              </Grid>

            </Grid>

          </Box>
        </Card>
        <Card className={classes.cardpedidospendentes}>
          <CardHeader title="" />
          <Divider />
          <Grid
            container
            spacing={3}
          >
            <Grid item xs={12} md={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sortDirection="desc">
                        <Tooltip
                          enterDelay={300}
                          title="Sort"
                        >
                          <TableSortLabel
                            active
                            direction="desc"
                          >
                            Data
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        Data Entrega
                      </TableCell>
                      <TableCell>
                        Tipo
                      </TableCell>
                      <TableCell>
                        Pedido Representante
                      </TableCell>
                      <TableCell>
                        Pedido Cliente
                      </TableCell>
                      <TableCell>
                        Código Cliente
                      </TableCell>
                      <TableCell>
                        Cliente
                      </TableCell>
                      <TableCell>
                        Motivo
                      </TableCell>
                      <TableCell>
                        Total
                      </TableCell>
                      <TableCell>
                        Situação
                      </TableCell>
                      <TableCell>
                        Situação Financeira
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pedidospendentes.map((pedido) => (
                      <TableRow
                        hover
                        key={pedido.num_pedido}
                      >
                        <TableCell>
                          {pedido.dataformatada}
                        </TableCell>
                        <TableCell>
                          {pedido.dataentregaformatada}
                        </TableCell>
                        <TableCell>
                          <Chip
                            avatar={<Avatar>{pedido.tipo.substring(0, 1)}</Avatar>}
                            label=""
                            variant="outlined"
                          />

                        </TableCell>
                        <TableCell>
                          {pedido.num_pedido}
                        </TableCell>
                        <TableCell>
                          {pedido.num_pedido_cliente}
                        </TableCell>
                        <TableCell>
                          {pedido.cod_cliente}
                        </TableCell>
                        <TableCell>
                          {pedido.cliente}
                        </TableCell>
                        <TableCell>
                          {pedido.motivoaprovacao}
                        </TableCell>
                        <TableCell>
                          {
                            new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                              minimumFractionDigits: 2
                            }).format(pedido.itens.map((item) => Number(item.vr_item_original)).reduce((prev, next) => prev + next, 0))
                          }
                        </TableCell>
                        <TableCell>
                          <Chip
                            color="primary"
                            label={pedido.situacao}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            color="primary"
                            label={pedido.situacao_financeira}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            icon={<ListAltIcon />}
                            onClick={() => { ItensPedido2(pedido.itens); }}
                            label="Itens Pedido"
                            size="small"
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            icon={<Delete />}
                            onClick={() => { cancelarPedidoHandle(pedido); }}
                            label="Cancelar Pedido"
                            size="small"
                            variant="outlined"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Card>
        <Card className={classes.cardpedidospendentes}>
          <CardHeader title="Resumo Diário - Total por Familia" />
          <Divider />
          <Grid
            container
            spacing={3}
          >
            <Grid item xs={12} md={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sumarizarfamilia.map((val) => (
                      <TableRow
                        hover
                        key={val.familiacomercial}
                      >
                        <TableCell>
                          {val.familiacomercial}
                        </TableCell>
                        <TableCell>
                          {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 2
                          }).format(val.total)}
                        </TableCell>
                        <TableCell>
                          {Number.parseFloat(val.peso_liquido_total).toFixed(2)} KG
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow
                      hover
                    >
                      <TableCell>
                        <b>Total vendas hoje</b>
                      </TableCell>
                      <TableCell>
                        <b>
                          {
                            new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                              minimumFractionDigits: 2
                            }).format(sumarizarfamilia.map((item) => Number(item.total)).reduce((prev, next) => prev + next, 0))
                          }
                        </b>
                      </TableCell>
                      <TableCell>
                        <b>
                          {
                            Number.parseFloat(sumarizarfamilia.map((item) => Number(item.peso_liquido_total)).reduce((prev, next) => prev + next, 0)).toFixed(2)
                          } KG
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Card>
      </div>

      <DialogTelaCheia isOpen={isOpen} handleClose={handleDialogClose} title="Pedidos" subtitle="">
        <MUIDataTable
          columns={columns}
          data={pedidos}
          options={options}
          components={{
            TableFilterList: CustomFilterList,
          }}
        />

      </DialogTelaCheia>

    </>
  );
};

const Results = () => {
  return (
    <PedidoDataTable />
  );
};

export default Results;
